import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <footer style={{
      margin: `0 auto`,
      maxWidth: 600,
      padding: `0px 1.0875rem 1.45rem`,
      paddingTop: 0,
  }}
  >
  © {new Date().getFullYear()}
  {" "}
        <a href="mailto:info@soundpump.net"
          style={{
            textDecoration: `none`,
          }}
        >
        {siteTitle}
        </a>
        
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
